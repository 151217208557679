import React from "react"
import MainLayout from "../components/layout/main"
import "../styles/main.scss"

import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import BlogPostVignette from "../components/blog/post_vignette"
import LinkResolver from "../link_resolver"
import styles from "../templates/blog_post.module.scss"

const Blog = ({data: {allPrismicBlogP: {nodes}, prismicBlogHome: {data, alternate_languages, lang}, allPrismicLanguageBanner: {bannerNodes}}}) => {
  let languageBanners = (
    alternate_languages.length > 0
      ?
      alternate_languages.map((language) => {
        let node = bannerNodes.find((node) => node.lang === language.lang);
        return <div className="slice" key={node.lang}>
          <div className={`container ${styles.languageBanner}`}>
            <div className="row">
              <p>{node.data.first_sentence.text}</p>
              <Link to={LinkResolver.resolveLink({type: "blog_home", document: {uid: language.uid, language: language.lang }})} style={{"color": "inherit"}}>
                <p>&nbsp;{node.data.name_to_link.text}&nbsp;</p>
              </Link>
              <p>{node.data.end_of_sentence.text}</p>
            </div>
          </div>
        </div>
      })
      :
      null
  )
  return <MainLayout title="Blog"
                     theme="light">
    <SEO title="Blog" description={data.page_description.text}/>
    { alternate_languages.length > 0 ? languageBanners : null }
    <div className="slice">
      <div className="container">
        <div className="row">
          {
            nodes
              .map(node => <div className="col-12 mb-spacing-48 mb-md-spacing-96 col-md-6" key={node.uid}>
                <Link to={LinkResolver.resolveLink({type: node.type, document: {uid: node.uid, language: lang}})} style={{"color": "inherit"}}>
                  <BlogPostVignette description_html={node.data.description.text} name={node.data.title.text} illustration={node.data.main_illustration} />
                </Link>
              </div>
              )
          }
        </div>
      </div>
    </div>
  </MainLayout>
}


export default Blog
export const pageQuery = graphql`
    query BlogPostListQuery($lang: String = "fr-fr") {
        prismicBlogHome(lang: {eq: $lang}) {
            data {
                page_description {
                    text
                }
            }
            alternate_languages {
                lang
            }
            lang
        }
        allPrismicLanguageBanner {
            bannerNodes: nodes {
                lang
                data {
                    end_of_sentence {
                        text
                    }
                    first_sentence {
                        text
                    }
                    name_to_link {
                        text
                    }
                }
            }
        }
        allPrismicBlogP (
            sort: {fields: [data___published_at], order: DESC},
            filter: {lang: {eq: $lang}, data: {is_published: {eq: "true"}}}
        ) {
            nodes {
                uid
                type
                data {
                    title {
                        text
                    }
                    description {
                        text
                    }
                    main_illustration {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 584, maxHeight: 385) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    published_at
                }
            }
        }
    }
`
