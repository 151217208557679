import React from "react"
import PropType from "prop-types"
import Img from "gatsby-image"

const BlogPostVignette = ({ illustration, name, description_html }) => {
  return (
    <React.Fragment>
      <div className="cropped-content mb-spacing-24">
        <Img fluid={illustration.localFile.childImageSharp.fluid} alt={name} className={"animated-image"}/>
        <div className="animated-image-overlay"/>
      </div>
      <h3 className={"mb-spacing-8 mb-md-spacing-16"}>
        {name}
      </h3>

      <div dangerouslySetInnerHTML={{ __html: description_html }}/>

    </React.Fragment>
  )
}

BlogPostVignette.propTypes = {
  illustration: PropType.any.isRequired,
  name: PropType.string.isRequired,
  description_html: PropType.string.isRequired
}

export default BlogPostVignette